
<template>
  <div class="taskSelect">
    <div
      :class="{
        'd-none d-md-block': check1(),
        sideBySide: check(),
      }"
    >
      <!-- <div :class="{ sideBySide: check() }"> -->
      <div :class="{ 'row': check() }">
        <div
          :class="{ 'col': check() }"
          v-for="(option, optionKey) in options"
          :ref="
            (el) => {
              itemsList.push(el);
            }
          "
          :key="optionKey"
        >
          <SingleOption
            :settings="data.settings"
            :option="option"
            :checkboxClicked="checkboxClicked"
            :isSelected="isSelected"
          />
        </div>
      </div>
    </div>

    <template v-if="check1()">
      <div class="d-block d-md-none">
        <Splide :options="splideOptions">
          <SplideSlide v-for="(option, optionKey) in options" :key="optionKey">
            <div>
              <!-- <img src="http://placehold.it/2000x1000" alt=""> -->
              <SingleOption
                :settings="data.settings"
                :option="option"
                :checkboxClicked="checkboxClicked"
                :isSelected="isSelected"
              />
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </template>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, computed, ref, onMounted } from "vue";
import { TaskTypes } from "@utils/Training";
import { AnswerHandler } from "@moods/taitaen-core";
import SingleOption from "./select/SingleOption.vue";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import LoadImages from './LoadImages'

import {
  setElementsHeightsAsEqual,
} from "@utils/General";

export default {
  name: "Select",
  components: {
    SingleOption,
    // Slick,
    Splide,
    SplideSlide,
  },
  props: ["data", "isTest", "taskId", "taskType"],
  setup(props) {
    const { createAnswer, doesTaskExist } = AnswerHandler();
    const answers = ref(createAnswer(props.taskId));
    const options = ref(null);

    const itemsList = ref([]);
    const optionContents = [];

    const splideOptions = {
      rewind: true,
      gap: "1rem",
    };

    function check() {
      let a = (props.data.settings && props.data.settings.renderItemsSideBySide) || props.taskId === '08d9790d-eff6-43fa-89bf-41da83701762';
      return a;
    }

    function check1() {
      let a = (props.data.settings && props.data.settings.showCarouselInMobile) || props.taskId === '08d9790d-eff6-43fa-89bf-41da83701762';
      return a;
    }

    onBeforeMount(() => {
      if (check()) {
        window.addEventListener("resize", onResize);
      }

      if (doesTaskExist(props.taskId)) {
        options.value = props.data.options;
      }
      if (props.data.options) {
        createAnswers(props.data.options, props.taskType);
        //const answerData = createAnswers(props.data)
        //answers.value = Object.assign(answers.value, answerData);
        //setAnswers(answers, createAnswers(props.data, props.taskType));
        options.value = props.data.options;
      }
    });

    onBeforeUnmount(() => {
      if (check()) {
        window.removeEventListener("resize", onResize);
      }
    });

    onMounted(() => {
      if (check()) {
        setOptionContents();
        let imageEls = getImageEls();
        if (imageEls) {
          new LoadImages(imageEls, setElementsHeights);
        }
      }
    })

    function setOptionContents() {
      if (itemsList.value) {
        for (let i in itemsList.value) {
          optionContents.push(
            itemsList.value[i].getElementsByClassName('optionContent')[0]
          )
        }
      }
    }

    function getImageEls() {
      let imageEls = [];
      for (let i in optionContents) {
        imageEls = imageEls.concat(optionContents[i].querySelector('img'));
      }
      return imageEls;
    }

    

    function onResize() {
      // if (window.innerWidth === lastWidth) {
      //   return;
      // }
      //lastWidth = window.innerWidth;

      setElementsHeights();
    }

    function setElementsHeights() {
      setElementsHeightsAsEqual(optionContents);
    }

    function isSelected(optionId, value) {
      let option = answers.value.find(({ id }) => id === optionId);
      // Multiselect
      if (Array.isArray(option.value)) {
        if (option.value.indexOf(value) !== -1) {
          return true;
        }

        // Singleselect
      } else if (option.value === value) {
        return true;
      }

      return false;
    }

    function createAnswers(data, taskType) {
      let obj = [],
        value;

      let dataObj;
      for (let i in data) {
        if (taskType === TaskTypes.SINGLESELECT) {
          value = null;
        } else if (taskType === TaskTypes.MULTISELECT) {
          value = [];
        }

        dataObj = {
          id: data[i].id,
          value: value,
        };
        answers.value.push(dataObj);
        obj.push(dataObj);
      }
      return obj;
    }

    function checkboxClicked(optionId, value) {
      let option = options.value.find(({ id }) => id === optionId);
      if (option) {
        let answer = answers.value.find(({ id }) => id === optionId);
        // Multiselect
        if (Array.isArray(answer.value)) {
          if (answer.value.indexOf(value) === -1) {
            answer.value.push(value);
          } else {
            let index = answer.value.indexOf(value);
            answer.value.splice(index, 1);
          }

          // Singleselect
        } else {
          answer.value = value;
        }
        /*
                if (answers.value[optionId].value.indexOf(value) === -1) {
                    answers.value[optionId].value.push(value);
                } else {
                    let index = answers.value[optionId].value.indexOf(value);
                    answers.value[optionId].value.splice(index, 1);
                }
                */
      }
    }

    /*
        function checkboxClicked(optionIndex, valueIndex) {
            if (answers.value[optionIndex].values[valueIndex]) {
                answers.value[optionIndex].values[valueIndex].value = !answers.value[optionIndex].values[valueIndex].value;
            }
        }
        */

    return {
      splideOptions,
      answers: computed(() => answers.value),
      options: computed(() => options.value),
      checkboxClicked,
      isSelected,
      itemsList,
      check,
      check1,
    };
  },
};
</script>

<style lang="scss">
.taskSelect {
  .sideBySide {
    display: flex;
  }
  .carousel__next,
  .carousel__prev {
    background-color: green;
  }
  .carousel__slide {
    align-items: normal;
  }

  .splide {
    padding-left: 60px;
    padding-right: 60px;
  }
  .splide__pagination {
    display: none;
  }
  .splide__arrow {
    top: 30%;
    transform: translateY(-30%);
  }
  .splide__arrow--prev {
    left: 0.3em;
  }
  .splide__arrow--next {
    right: 0.3em;
  }

  .answersOnRows {
    .answer {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }
  .answer {
    /*
        display: flex;
        align-items: center;
        */
    cursor: pointer;

    span {
      padding-left: 20px;
      padding-right: 30px;
    }
  }
}
</style>
